@import "src/content/styles/embla-variables";

.items:focus-visible {
  outline: none;
}

.item.active {
  background-color: #e9ecef;
}

.expandedItems > li > :global(.dropdown-item) {
  padding-left: $spacing-l;
}

@import "src/content/styles/embla-variables";

.info-note {
  min-width: 0;

  &__title {
    color: $color-gray-70;
  }

  &--fit-content {
    min-width: fit-content;
  }
}

.formDescription {
  margin-bottom: 1.3rem;
}

.formContent {
  flex-grow: 1;

  :global(.form-group) {
    margin-bottom: 1.3rem;
  }
}

@import "src/content/styles/embla-variables";

.fullSizePdf {
  width: 100%;
  height: 100%;
}

.fullSizeScrollable {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.minWidth {
  width: 100px !important;
}

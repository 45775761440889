/* stylelint-disable selector-class-pattern */
@import "src/content/styles/embla-variables";
@import "src/content/styles/variables";

.documentWrapper.documentWrapper.documentWrapper {
  position: absolute;
  top: $spacing-m;
  bottom: 0;
  width: $pdf-sidebar-width;
  z-index: 1000;

  .documentFixedListScroll {
    margin-left: auto;
    margin-right: auto;
  }

  &.expanded {
    width: $pdf-sidebar-width-expanded;
  }

  .document {
    width: 100%;
    padding: 0;
  }

  .sidebarScroll {
    flex: 1;
    overflow: hidden;
  }
}

.active {
  outline: $color-primary solid;
}

.noOutline {
  outline: none;
}

@import "src/content/styles/variables";

@function to-rgb($color) {
  @return red($color) + ", " + green($color) + ", " + blue($color);
}

$primary-as-rgb: to-rgb($color-primary);

.background {
  --focusbox-background-color: rgba(#{$primary-as-rgb}, 0.2);
  background-color: var(--focusbox-background-color);
}

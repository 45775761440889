@import "src/content/styles/embla-variables";

.participantBadge {
  display: flex;
  justify-content: center;
  height: 32px;

  &.profilePicture {
    border-radius: 50%;
    display: flex;
  }
}

.nameField {
  display: flex;
  align-items: center;
  justify-content: center;
}

@import "src/content/styles/embla-variables";

.container {
  min-width: max-content;
  color: $color-gray-60;

  svg.statusIcon {
    fill: $color-gray-60;
  }
}

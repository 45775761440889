/* stylelint-disable selector-class-pattern */
@import "src/content/styles/embla-variables";

.floatingPageCount {
  position: absolute;
  z-index: 3;
  background-color: $color-gray-45 !important;

  top: -10px;
  right: -10px;
}

.secondPage {
  position: absolute;

  right: -6px;
  top: -6px;

  width: 85px;
  height: 120px;

  border: 2px solid #dbdbdb;

  box-shadow: $shadow-1;
}

.thumbnailOverride {
  border: 2px solid #dbdbdb !important;
}

.status {
  position: absolute;
  z-index: 999;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  + .content {
    visibility: hidden;
  }
}

.loading {
  opacity: 0.5;
  cursor: not-allowed;
}

@import "src/content/styles/embla-variables";

.popOverPanel {
  position: absolute;
  top: 38px;
  right: -10px;
}

.documentHitsBody {
  overflow-y: auto;
  max-height: 400px;
}

.markingHitsBody {
  overflow-y: auto;
  max-height: 400px;
}

.popOverPanelCard {
  width: 400px;
}

.documentIcon {
  span {
    svg {
      height: 25px;
      width: 25px;
    }
  }
}

.filterBadge {
  user-select: none;
}

.filterBadge:hover {
  cursor: pointer;
}

.contentHitRow {
  &:hover,
  &:focus {
    outline: none;
    background-color: #116c9b1a;
    transition: background-color 0.3s linear;
    cursor: pointer;
  }
}

.contentHitRow {
  user-select: none;
  transition: background-color 0.3s linear;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.minWidth0 {
  min-width: 0;
}

.pageNumber {
  white-space: nowrap;
}

.searchInputBtnGroup {
  position: absolute;
  display: flex;
  cursor: pointer;
  align-items: center;
  right: 0;
  top: 0;
  user-select: none;
}

#searchInput {
  padding-right: 150px;
}

@import "src/content/styles/embla-variables";

// Theres is a style bug, where border (border-top) between rows is doubled:
// - Elements: <tr> or <td>
// - I think border-top gets applied on both ::after + ::before
// - Bug disapears when: "box-sizing: border-box" is removed in _reboot.scss
// - Could maybe be associated to this: https://stackoverflow.com/questions/6750729/css-inner-border-broken-layout

.table {
  caret-color: transparent;
}

.selectedActionRow {
  background-color: $color-gray-95;
  transition:
    height 0.15s ease-in-out,
    padding 0.3s ease-in-out;
  height: 65px;

  h5 {
    color: rgba(51, 51, 51, 0.7);
  }
}

.height0 {
  height: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.expandedHideBorderTop {
  border-top: 0 solid $color-gray-85 !important;
}

:global(.card) .table {
  margin-bottom: 0;

  > thead,
  > tbody {
    &:first-child > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }
}

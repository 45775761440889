@import "src/content/styles/embla-variables";

.container {
  padding: 12px 8px;
  border-radius: 4px;
  border: 1px solid $color-gray-85;
  background-color: $color-white;
  cursor: pointer;
  margin: 1px;
  margin-bottom: 4px;

  &:hover {
    background-color: $color-gray-95;
  }
}

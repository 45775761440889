@import "src/content/styles/embla-variables";

.wrapper {
  display: flex;
  justify-content: center;
  padding-block: 1rem;
  border-top: #{$default-border};
}

.separator {
  color: $color-gray-45 !important;
  pointer-events: none;
}

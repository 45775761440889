@import "src/content/styles/embla-variables";

.resizable {
  position: absolute;
}

.notClickable {
  // disable mouse events on container itself, but allow on content
  pointer-events: none;

  * {
    pointer-events: all;
  }
}

.resizeBoundary {
  position: absolute;
  inset: 0;
  z-index: 2000;
}

.active {
  border: 1px dashed $color-primary;
}

.resizingWidth {
  cursor: col-resize;
}

.resizingHeight {
  cursor: row-resize;
}

.resizingNesw {
  cursor: nesw-resize;
}

.resizingNwse {
  cursor: nwse-resize;
}

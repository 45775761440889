@import "src/content/styles/embla-variables";

.menuContainer {
  position: relative;
}

.dropdownMenu {
  z-index: 100000 !important;
  margin: 0 !important;

  &:hover {
    cursor: default;
  }

  &:focus-visible {
    outline: none;
  }

  .dropdownItem {
    cursor: pointer;
  }
}

.disabled {
  cursor: default !important;
  pointer-events: all !important;

  &:active {
    // Only remove pointer events when active, so tooltip can be shown on hover
    pointer-events: none;
  }
}

.border {
  border-top: 1px solid $color-gray-85;
}

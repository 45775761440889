.AddPresentationsForm {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.formHeader {
  margin-bottom: 1.3rem;
}

.formDescription {
  margin-bottom: 1.3rem;
}

.formContent {
  flex-grow: 1;
}

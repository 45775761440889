@import "src/content/styles/embla-variables";

.toolbar {
  z-index: 1001;
  position: relative;
  display: grid;
  grid-template-columns: min-content minmax(0, 1fr) min-content;
  gap: 15px;

  .title {
    line-height: 32px;
  }

  .draftButton {
    background-color: $color-gray-60;
  }
}

.EditButtonBorder {
  border: 1px solid $color-gray-70 !important;
}

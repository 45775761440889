@import "src/content/styles/embla-variables";

.card {
  gap: $spacing-s;
  padding: $spacing-s !important;
}

.button {
  &.active svg {
    fill: $color-primary-light !important;
  }

  svg {
    fill: $color-gray-15 !important;
  }
}

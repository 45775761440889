@import "src/content/styles/embla-variables";

button.removeParticipantBtn {
  border-color: transparent;

  svg {
    fill: $color-primary;
  }

  &:hover {
    border-color: $color-primary;
  }
}

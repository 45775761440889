@import "src/content/styles/variables";

.popover {
  z-index: 2002;
  width: max-content;
  min-width: 200px;
}

.dropdown {
  width: 150px;
}

.iconColor {
  fill: $color-primary;
}

@import "src/content/styles/embla-variables";

.repliesBody {
  overflow-x: hidden;
  overflow-y: auto;
}

.repliesInputCard {
  border-top: #{$default-border};
  padding: 10px;
}

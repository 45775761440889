@import "src/content/styles/embla-variables";

.thumbnailIcons {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: $spacing-xxs;
  bottom: $spacing-xxs;

  &:not(.alignRight) {
    left: $spacing-xxs;
  }

  &.alignRight {
    right: $spacing-xxs;
  }
}

﻿@import "src/content/styles/embla-variables";

$background-color: rgba(241, 245, 248, 0.5);

body.preload * {
  // ".preload" class is fix for IE transition jump on page loads - it should be removed on $(document).ready(). See https://css-tricks.com/transitions-only-after-page-load/
  transition: none !important;
}

body {
  /* Trying to fix font not rendering */
  animation-delay: 0.1s;
  animation-duration: 0.1s;
  animation-iteration-count: 1;
  animation-name: fontfix;
  animation-timing-function: linear;
  background-color: $background-color;

  &.sidebar-hidden {
    div#body-wrapper {
      margin-left: 50px;
    }
  }
}

a {
  cursor: pointer;
}

.error-actions {
  margin-top: 20px;
}

.error-id {
  margin-top: 10px;
}

.align-items-center {
  align-items: center;
}

.flex-container {
  display: flex;
}

p.no-margin-bottom {
  margin-bottom: 0;
}

.card.no-margin-bottom {
  margin-bottom: 0;
}

@keyframes fontfix {
  from {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

div#body-wrapper {
  margin-bottom: 30px;
  margin-left: $left-side-menu-width;
  transition: margin-left $left-side-menu-transition-duration;
}

div.body-content-full-width {
  margin-top: 20px;

  .nav-tabs {
    padding-left: 50px;
  }
}

div.body-content {
  margin-left: 0;
  //margin-top: 25px; /* Remove top bar */
  padding-left: 50px;
  padding-right: 50px;
}

div.word-wrap {
  word-wrap: break-word;
}

@media (max-width: 767px) {
  div.body-content-full-width {
    margin-top: 10px;

    .nav-tabs {
      padding-left: 15px;
    }
  }

  div.body-content {
    padding-left: 15px;
    padding-right: 15px;
  }

  div#body-wrapper {
    margin-left: 0 !important;
  }
}

.clickable-row {
  cursor: pointer;
}

.exclude-click-event {
  cursor: default !important;
}

.break-word {
  hyphens: auto;
  word-break: break-word;
  word-wrap: break-word;
}

.break-spaces {
  white-space: break-spaces;
}

.h-100vh {
  height: 100vh;
}

.gap-s {
  gap: $spacing-s;
}

.gap-m {
  gap: $spacing-m;
}

/* fix embla input-group borders */
.input-group {
  .input-group-prepend > button {
    border-color: $color-gray-70;
    border-right: 0;
  }

  .form-control.form-control.form-control {
    border: 1px solid $color-gray-70;
  }

  .input-group-append > button {
    border-color: $color-gray-70;
    border-left: 0;
  }
}

@import "src/content/styles/variables";

.selected {
  border: 1px solid $color-primary;
}

.inactive {
  opacity: 0.7;
}

.hover {
  :hover {
    cursor: pointer;
  }
}

.small {
  font-size: 80%;
  font-weight: 600;
  padding: 2px 4px;
}

.breakWord {
  word-break: break-word;
}

@import "src/content/styles/variables";

html body {
  background-color: #f6f6f6;
}

.page-content {
  .title-bar-container {
    align-items: center;
    display: flex;
    margin-top: 2rem;
    padding: 0 $page-content-horizontal-padding;

    .title-bar-actions {
      margin-left: auto;
    }
  }

  .body-content {
    margin-top: 1rem;
    padding-top: 0;
  }

  &.no-left-menu {
    .title-bar-container {
      padding: 0 $fullscreen-left-right-padding;
    }

    .body-content {
      padding: 0 $fullscreen-left-right-padding;
    }
  }

  &.fullscreenMode {
    margin-top: $topbar-heigth;

    .body-content {
      margin-top: 0;
      padding: 0;
    }
  }
}

@import "src/content/styles/embla-variables";

.menuContainer {
  position: relative;
}

.dropdownMenu {
  z-index: 9999999 !important;
  margin: 0 !important;

  &:hover {
    cursor: default;
  }

  .dropdownItem {
    cursor: pointer;

    &.disabled {
      cursor: default;
      pointer-events: all;

      &:active {
        // Only remove pointer events when active, so tooltip can be shown on hover
        pointer-events: none;
      }
    }
  }
}

.border {
  border-top: 1px solid $color-gray-85;
}

@import "src/content/styles/variables";

:global(.badge.badge-success-light) {
  background-color: #{$color-green};
  color: #{$color-white};
}

:global(.badge.badge-wide) {
  padding: #{$badge-vertical-padding} #{$padding-s};
}

:global(.badge) {
  letter-spacing: 0.5px;
}

@import "src/content/styles/embla-variables";

.container {
  background-color: $color-gray-95;
  border-radius: $border-radius-sm;
  padding: $padding-xs;

  p {
    font-weight: $font-weight-bold;
  }
}

.warningText {
  color: $color-danger;
  text-align: start;
}

@import "src/content/styles/embla-variables";

.edgeHandle {
  --handleOffset: -1px;

  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: "";
    background-color: $color-primary;
    height: 8px;
    width: 8px;
    border-radius: 50%;
  }
}

.northHandle,
.southHandle {
  width: 100%;
  cursor: row-resize;
}

.northHandle {
  top: var(--handleOffset);
  transform: translateY(-50%);
}

.southHandle {
  bottom: var(--handleOffset);
  transform: translateY(50%);
}

.eastHandle,
.westHandle {
  height: 100%;
  cursor: col-resize;
}

.westHandle {
  left: var(--handleOffset);
  transform: translateX(-50%);
}

.eastHandle {
  right: var(--handleOffset);
  transform: translateX(50%);
}

.cornerHandle {
  position: absolute;
  width: 15px;
  height: 15px;
}

.northWestHandle {
  transform: translate(-50%, -50%);
  cursor: nwse-resize;
}

.northEastHandle {
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  cursor: nesw-resize;
}

.southWestHandle {
  bottom: 0;
  transform: translate(-50%, 50%);
  cursor: nesw-resize;
}

.southEastHandle {
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%);
  cursor: nwse-resize;
}

@import "src/content/styles/embla-variables";

.pill-badge {
  align-items: center;
  color: $color-white;
  display: flex;
  justify-content: center;
  padding: 4px 8px;
  gap: 4px;

  &--m {
    height: 40px;
    font-size: 12px;
  }

  &--s {
    height: 32px;
    font-size: 10px;
  }

  &--xs {
    height: 24px;
    font-size: 8px;
  }

  &--xxs {
    height: 18px;
    font-size: 6px;
  }

  &--primary {
    background-color: $color-primary;
  }

  &--secondary {
    color: $color-gray-15;
    background-color: $color-secondary;
  }

  &--secondary-light {
    color: $color-gray-15;
    background-color: $color-secondary-light;
  }
}

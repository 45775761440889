@import "src/content/styles/embla-variables";

.documentIcon {
  span {
    svg {
      fill: $color-primary;
      height: 30px;
      width: 30px;
    }
  }
}

.documentIconError {
  span {
    svg {
      fill: $color-danger;
      height: 30px;
      width: 30px;
    }
  }
}

.cancelIcon:hover {
  cursor: pointer;
}

.fileSize {
  min-width: 55px;
}

.minWidth0Important {
  min-width: 0 !important;
}

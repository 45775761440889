@import "src/content/styles/embla-variables";

.success {
  fill: $color-success-dark !important;

  &.inverted {
    fill: $color-success !important;
  }
}

.error {
  fill: $color-danger-dark !important;

  &.inverted {
    fill: $color-danger !important;
  }
}

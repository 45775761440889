@import "src/content/styles/variables";

.cardsContainer {
  flex-grow: 1;
  flex-shrink: 0;
  overflow: hidden auto;
  padding-left: 8px; // To show shadow
  padding-right: 8px;

  &.presentationEdit {
    background-color: $color-gray-95;
  }
}

.overlayElement {
  position: absolute;
  z-index: 1001;
}

.highlight {
  z-index: 1002;
}

.overlayBtn {
  z-index: 1003;
}

.notActive {
  &,
  * {
    pointer-events: none !important;
  }
}

:global(.card) {
  &.addedToPresentation {
    border-color: $color-primary;
  }
}

.transitions {
  transition:
    box-shadow $default-transition,
    border $default-transition,
    opacity $default-transition,
    background-color $default-transition;
}

.faded {
  opacity: $faded-marking-opacity;
}

.commentFaded {
  opacity: $faded-comment-opacity;
}

.container {
  position: relative;
}

.markingsContainer {
  height: 0;
  width: 0;
}

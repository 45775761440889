@import "src/content/styles/embla-variables";
@import "src/content/styles/variables";

.templateCard {
  width: 300px;
  position: absolute;
  top: $toolbar-height / 2 + 5px;
  border: unset !important;
}

.divider {
  background-color: $color-gray-95;
  width: 2px;
}

.option {
  cursor: pointer;
  min-width: 200px;
}

.optionIcon {
  min-width: 30px;
  text-align: center;
}

.draggable {
  cursor: grab;
}

.isDragging {
  opacity: 1;
}

.isOver {
  opacity: 0;
}

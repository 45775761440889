@import "src/content/styles/variables";

.markingIcon {
  min-width: 0;

  svg {
    fill: $color-icon;
    min-width: 24px;
    min-height: 24px;
  }
}

.hovered {
  border: 1px solid $color-primary !important;
}

.inactive {
  opacity: 0.7;
}

@import "src/content/styles/embla-variables";

.commentContainer {
  max-width: 500px;
  z-index: 1500;
}

.opaque {
  opacity: 1;
}

.tagNameOverflow {
  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.borderBottom {
  border-bottom: 1px solid $color-gray-95;
}

.badge {
  background-color: $color-primary;
  padding: 4px;
  border-radius: 50%;
  border-end-start-radius: 0;
}

/*
    http://bootsnipp.com/snippets/featured/responsive-navigation-menu
*/
@import "embla-variables";

$break-lg: 1200px;
$break-md: 992px;
$break-sm: 768px;
$break-xs: 480px;

$fullscreen-left-right-padding: 128px;

$left-side-menu-width: 250px !default;
$left-side-menu-transition-duration: 0.3s !default;
$header-border-color: #eee;
$header-border-color-dark: darken($header-border-color, 10%);

$color-green: #52cf92;

$faded-marking-opacity: 0.35;
$faded-comment-opacity: 0.6;
$badge-vertical-padding: $padding-xs;

$color-icon: $color-gray-45;

$default-transition: 0.15s ease-in-out;

$topbar-heigth: 50px;
$toolbar-height: 56px;
$bottombar-height: 102px;
$tab-button-height: 43px;
$present-top-bar-height: 56px;
$pdf-sidebar-width: 128px;
$pdf-sidebar-width-expanded: 510px;

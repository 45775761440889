@import "src/content/styles/embla-variables";

.pageHeader,
.pageOverlay {
  position: absolute;
  z-index: 2500;
}

.pageOverlay {
  inset: 0;
}

.loadingSpinner {
  position: absolute;
  inset: 0;
}

.pdfLoader {
  height: var(--pdfHeight);
  width: var(--pdfWidth);
  background-color: #fff;
  box-shadow: $shadow-2;
}

@import "src/content/styles/embla-variables";

.bracketsContainer {
  border: 2px solid $color-gray-85;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Brackets {
  display: block;
  border: 3px solid $color-gray-70;
  border-style: none solid;
  position: relative;
  width: 70%;
  height: 75%;

  &::before,
  &::after {
    content: "";
    height: 100%;
    width: 3px;
    position: absolute;
    border-top: 3px solid $color-gray-70;
    border-bottom: 3px solid $color-gray-70;
  }

  &::before {
    top: 0;
    left: 0;
  }

  &::after {
    bottom: 0;
    right: 0;
  }
}

.lineStyle {
  border: 2px solid $color-gray-85;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: "";
    display: block;
    width: 70%;
    height: 75%;
    border-width: 3px;
    border-color: $color-gray-70;
  }

  &.Single::after {
    border-style: none solid;
  }

  &.Double::after {
    border-width: 6px;
    border-style: none double;
  }

  &.LeftOnly::after {
    border-left-style: solid;
  }

  &.RightOnly::after {
    border-right-style: solid;
  }
}

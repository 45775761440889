@import "src/content/styles/embla-variables";

.markdownRender {
  &.smallest {
    p,
    ul,
    ol {
      font-size: $font-size-base * 0.4;
    }

    h1 {
      font-size: $font-size-h1 * 0.4;
    }

    h2 {
      font-size: $font-size-h2 * 0.4;
    }

    h3 {
      font-size: $font-size-h3 * 0.4;
    }

    h4 {
      font-size: $font-size-h4 * 0.4;
    }

    h5 {
      font-size: $font-size-h5 * 0.4;
    }
  }

  &.smaller {
    p,
    ul,
    ol {
      font-size: $font-size-base * 0.6;
    }

    h1 {
      font-size: $font-size-h1 * 0.6;
    }

    h2 {
      font-size: $font-size-h2 * 0.6;
    }

    h3 {
      font-size: $font-size-h3 * 0.6;
    }

    h4 {
      font-size: $font-size-h4 * 0.6;
    }

    h5 {
      font-size: $font-size-h5 * 0.6;
    }
  }

  &.small {
    p,
    ul,
    ol {
      font-size: $font-size-base * 0.8;
    }

    h1 {
      font-size: $font-size-h1 * 0.8;
    }

    h2 {
      font-size: $font-size-h2 * 0.8;
    }

    h3 {
      font-size: $font-size-h3 * 0.8;
    }

    h4 {
      font-size: $font-size-h4 * 0.8;
    }

    h5 {
      font-size: $font-size-h5 * 0.8;
    }
  }

  &.medium {
    p,
    ul,
    ol {
      font-size: $font-size-base;
    }

    h1 {
      font-size: $font-size-h1;
    }

    h2 {
      font-size: $font-size-h2;
    }

    h3 {
      font-size: $font-size-h3;
    }

    h4 {
      font-size: $font-size-h4;
    }

    h5 {
      font-size: $font-size-h5;
    }
  }

  &.large {
    p,
    ul,
    ol {
      font-size: $font-size-base * 1.5;
    }

    h1 {
      font-size: $font-size-h1 * 1.5;
    }

    h2 {
      font-size: $font-size-h2 * 1.5;
    }

    h3 {
      font-size: $font-size-h3 * 1.5;
    }

    h4 {
      font-size: $font-size-h4 * 1.5;
    }

    h5 {
      font-size: $font-size-h5 * 1.5;
    }
  }

  &.larger {
    p,
    ul,
    ol {
      font-size: $font-size-base * 2;
    }

    h1 {
      font-size: $font-size-h1 * 2;
    }

    h2 {
      font-size: $font-size-h2 * 2;
    }

    h3 {
      font-size: $font-size-h3 * 2;
    }

    h4 {
      font-size: $font-size-h4 * 2;
    }

    h5 {
      font-size: $font-size-h5 * 2;
    }
  }

  &.largest {
    p,
    ul,
    ol {
      font-size: $font-size-base * 3;
    }

    h1 {
      font-size: $font-size-h1 * 3;
    }

    h2 {
      font-size: $font-size-h2 * 3;
    }

    h3 {
      font-size: $font-size-h3 * 3;
    }

    h4 {
      font-size: $font-size-h4 * 3;
    }

    h5 {
      font-size: $font-size-h5 * 3;
    }
  }
}

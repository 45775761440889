@import "src/content/styles/embla-variables";

.tooltip {
  background: $color-white;
  padding: 4px 8px;
  border-radius: $border-radius;
  box-shadow: $shadow-1;
  color: #333 !important;
  max-width: 100%;
  overflow-wrap: break-word;
  z-index: 100000;
}

.arrow,
.arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
}

.arrow::before {
  content: "";
  transform: rotate(45deg);
  background: $color-white;
}

.tooltip {
  &[data-popper-placement^="top"] > .arrow {
    bottom: -4px;
  }

  &[data-popper-placement^="bottom"] > .arrow {
    top: -4px;
  }

  &[data-popper-placement^="left"] > .arrow {
    right: -4px;
  }

  &[data-popper-placement^="right"] > .arrow {
    left: -4px;
  }
}

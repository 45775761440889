@import "src/content/styles/embla-variables";

.sidebarWrapper {
  max-width: 500px;
  min-width: 350px !important;
  width: 33%;
  z-index: 1000;
  border-radius: unset !important;
  border-top: unset !important;

  .sidebarScroll {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
  }
}

.addDocumentsButtonContainer {
  border-top: 1px solid $color-gray-85;
  padding: 12px 16px;
}

.documentHover {
  margin: 0;
  border-right: 2px solid $color-primary;
}

@import "src/content/styles/embla-variables";

.container {
  overflow-x: auto;
}

.tab {
  letter-spacing: 0.5px;
}

.active {
  background-color: #116c9b33;
}

.title {
  max-width: 200px;
}

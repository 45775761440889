@import "src/content/styles/embla-variables";

/* stylelint-disable selector-class-pattern -- Disabled as these class names are defined by react-mentions and can't be changed */
.error .mentions__input {
  border: 1px solid $validation-error !important;
}

.mentions--multiLine {
  .mentions__control {
    border-color: $color-gray-60;
    box-shadow: none;
  }

  .mentions__input,
  .mentions__highlighter {
    border: 1px solid $color-gray-60;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    outline: 0;
    box-shadow: none;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    color: #333;
    background-clip: padding-box;
    min-height: var(--min-heigth);
    max-height: var(--max-heigth);
    overflow: auto !important;

    &:focus {
      box-shadow: 0 0 0 3px rgba(108, 112, 132, 0.5);
      transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    }
  }

  .mentions__highlighter {
    border: 1px solid transparent !important;
  }

  .mentions__mention {
    position: relative;
    z-index: 1;
    color: $color-primary-light;
    text-shadow:
      1px 1px 1px #fff,
      1px -1px 1px #fff,
      -1px 1px 1px #fff,
      -1px -1px 1px #fff;
    pointer-events: none;
  }

  .mentions__suggestions__list {
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden;
  }
}

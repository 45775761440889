@import "src/content/styles/embla-variables";

.button {
  position: relative;
}

.status {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  + .content {
    visibility: hidden;
  }
}

.borderless {
  border-color: transparent !important;

  &:focus:not(:focus-visible) {
    box-shadow: none !important;
  }

  &:active {
    background-color: transparent !important;
    color: $color-primary-light !important;

    svg {
      fill: $color-primary-light !important;
    }
  }
}

@import "src/content/styles/embla-variables";

button.presentButton {
  margin-right: $spacing-s;

  svg {
    fill: $color-white !important;
  }

  &,
  &:focus {
    background-color: $color-gray-70;
  }

  &:hover {
    background-color: $color-gray-85;
  }

  &:active {
    background-color: $color-gray-60 !important;
  }
}

@import "src/content/styles/embla-variables";

.outlineContainer {
  width: 43px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed $color-gray-85;
  background-color: $color-white;
  box-shadow: $shadow-1;

  &.clickable {
    cursor: pointer;

    &:hover {
      box-shadow: $shadow-2;
    }
  }
}

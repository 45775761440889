.verticalLines {
  --vertical-lines-width: 3px;
  --brackets-width: 5px;
}

.single {
  border-left: var(--vertical-lines-width) solid var(--vertical-lines-color);
  border-right: var(--vertical-lines-width) solid var(--vertical-lines-color);
}

.double {
  border-left: calc(var(--vertical-lines-width) * 2) double var(--vertical-lines-color);
  border-right: calc(var(--vertical-lines-width) * 2) double var(--vertical-lines-color);
}

.leftOnly {
  border-left: var(--vertical-lines-width) solid var(--vertical-lines-color);
}

.rightOnly {
  border-right: var(--vertical-lines-width) solid var(--vertical-lines-color);
}

.brackets {
  border-right: var(--vertical-lines-width) solid var(--vertical-lines-color);
  border-left: var(--vertical-lines-width) solid var(--vertical-lines-color);

  &::before,
  &::after {
    content: "";
    height: 100%;
    width: var(--brackets-width);
    position: absolute;
    border-top: var(--vertical-lines-width) solid var(--vertical-lines-color);
    border-bottom: var(--vertical-lines-width) solid var(--vertical-lines-color);
  }

  &::before {
    top: 0;
    left: 0;
  }

  &::after {
    bottom: 0;
    right: 0;
  }
}

@import "src/content/styles/embla-variables";

.wrapper {
  --active-outline-width: 3px;
  margin: var(--active-outline-width);
}

.thumbnail {
  overflow: hidden;
  background-color: $color-gray-95;
  box-shadow: $shadow-1;

  &.active {
    outline: $color-primary solid var(--active-outline-width);
  }
}

@import "src/content/styles/embla-variables";

.popoverPanel {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60vh;
  z-index: 9999;
}

.navigationItem {
  &:focus {
    background-color: #e9f1f5;
    outline: none;
  }
}

﻿@import "./embla-variables";

html body nav.navbar .navbar-nav .dropdown-menu {
  border-radius: 4px;

  svg.embla-icon {
    fill: $color-gray-45;
  }
}

body .body-container .left-menu {
  z-index: 0;
}

.card {
  > table {
    border-bottom: 0;
  }
}

div .modal-content {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

ul.nav-tabs.navbar-top.tab-group > li.nav-item > a.nav-link.active:hover,
ul.nav-tabs.navbar-top.tab-group > li.nav-item > a.nav-link.active:focus,
ul.nav-tabs.navbar-top.tab-group > li.nav-item > a.nav-link.active {
  border-left: 0;
  border-right: 0;
}

table {
  > tbody > tr:first-child > td {
    border-top: 0;
  }

  .table-header {
    border-bottom: $default-border;
  }
}

.card {
  transition:
    border-color $default-transition,
    box-shadow $default-transition;
}

.card > .card-body.small {
  padding: 10px;
}

svg.color-white {
  fill: $color-white;
}

.element-status-icon.element-type-div {
  box-shadow: none;
  background-color: transparent;
  border: 0;
  margin-right: 10px;
}

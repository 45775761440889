button.button {
  border: 0;
  border-radius: 50%;
}

.bigSize {
  height: 43px;

  svg.icon {
    height: 30px;
    width: 30px;
  }
}

.fixedPosition {
  position: fixed;
  right: 20px;
  width: 43px;
  bottom: 20px;
}

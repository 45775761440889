@import "src/content/styles/variables";

.container {
  z-index: 100;
  background-color: $color-gray-95;
  padding: 0 !important;
}

.tabs {
  border-bottom: 1px solid $color-gray-85 !important;

  button {
    background-color: $color-gray-95 !important;
    height: $present-top-bar-height;
    display: flex;
    justify-content: center;
  }
}

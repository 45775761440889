@import "src/content/styles/embla-variables";

.loader {
  align-items: center;
  box-shadow: $shadow-2;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

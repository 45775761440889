@import "src/content/styles/embla-variables";

.container {
  width: 100%;
  padding: 0 0.6em;
  overflow: hidden;
  z-index: 1;
  position: absolute;

  .header {
    font-size: 1em;
  }

  .page {
    color: $color-gray-85;
    font-size: 0.75em;
  }
}

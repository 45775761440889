.dropdown-menu {
  &.dropdown-menu-with-icon-first {
    margin-left: $margin-m;
  }

  .sub-dropdown {
    > a.sub-dropdown-toggle {
      &:active {
        background-color: inherit;
        color: inherit;
      }

      svg.embla-icon.arrow {
        fill: $text-color;
        transition: transform 250ms ease-in-out;
      }

      &.collapsed {
        svg.embla-icon.arrow {
          transform: rotate(180deg);
        }
      }
    }

    .sub-dropdown-content > .sub-dropdown-item a.dropdown-item {
      padding: $padding-xxs 0 $padding-xxs $padding-m;
    }
  }
}

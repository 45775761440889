@import "src/content/styles/variables";

$subject-height: 19px;
$subject-hidden-width: 20px;
$subject-hidden-margin: 4px;
$subjects-gap: 2px;
$presentation-icon-size: 14px;
$name-length: 140px;

.subjectsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: $subjects-gap;
  justify-content: flex-end;
  overflow: hidden;
  max-height: $subject-height;
}

.subjectsHiddenCount {
  height: $subject-height;
  line-height: $subject-height;
  margin-left: 4px;
}

.markingIcon {
  svg {
    fill: $color-icon;
    width: 24px;
    height: 24px;
  }
}

.presentationSubjectsContainer {
  max-width: 160px;

  &.wide {
    max-width: 160px;
  }
}

.presentationsContainer {
  max-height: $subject-height;
  max-width: 100%;
}

.presentationBadge {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.presentationBadgeIcon {
  position: absolute;
  width: $presentation-icon-size;
  height: $presentation-icon-size;
  left: 6px;
  top: 2px;

  fill: $color-icon;

  transition: fill $default-transition;
}

.presentationBadgeText {
  margin-left: calc($presentation-icon-size);
}

.isPrivate {
  align-items: center;
  display: flex;
}

.tagNameOverflow {
  max-width: $name-length;
  text-overflow: ellipsis;
  overflow: hidden;
}

.expandTaglist {
  display: flex;
  flex-wrap: wrap;
  gap: $subjects-gap;
  justify-content: flex-end;
}

.nameContainer {
  max-width: $name-length;
}

.nonSelectable {
  user-select: none;
}

@import "src/content/styles/variables";

.speakerNoteCreateCardHidden {
  max-height: 0;
  overflow: hidden;
  transition: all 0.4s ease;
}

.speakerNoteCreateCardVisible {
  max-height: 800px;
  overflow: hidden;
  transition: all 1s ease;
}

.speakerNoteContainer {
  height: 95%;
}

.container {
  height: calc(100vh - $topbar-heigth);
}

.speakerNotesContainer {
  background-color: $color-gray-95;
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
}

@import "src/content/styles/embla-variables";

$icon-size: 20px;
$icon-rigth: 4%;

.input-icon-container {
  position: relative;

  input {
    padding-right: calc(($icon-rigth * 2) + $icon-size); // set padding to icon width
  }

  .input-icon {
    bottom: 15%;
    height: $icon-size;
    line-height: 70%;
    position: absolute;
    right: $icon-rigth;
    top: 15%;
    width: $icon-size;

    svg {
      height: $icon-size;
      width: $icon-size;
    }
  }
}

@import "src/content/styles/embla-variables";

$modal-footer-height: 57px;
$modal-header-height: 81px;

.modalHeader {
  height: $modal-header-height;
}

.formContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  row-gap: 24px;
  column-gap: 24px;
  overflow-y: auto;
  height: calc(100% - $modal-footer-height - $modal-header-height);
}

.modalFooter {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: $modal-footer-height;
}

.revertButton {
  text-decoration: underline;
  color: $color-primary;
  cursor: pointer;
}

.documentNameTooltip {
  width: 50%;
  max-width: 800px;
}

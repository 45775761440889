@import "src/content/styles/embla-variables";

$color-search-hit: rgb(0, 255, 242);
$canvas-z-index: 1000;

.pageContainer {
  background-color: $color-white;
  position: absolute;
  pointer-events: all; // readd pointer events, removed by react-window while scrolling, to fix toolbox blinking

  .disableDefaultSelection ::selection {
    background: transparent;
  }
}

.page {
  box-shadow: $shadow-2;

  // stylelint-disable-next-line selector-class-pattern
  :global(.react-pdf__Page__annotations) {
    z-index: -1;

    // hide all annotations except links
    > :global(:not(.linkAnnotation)) {
      display: none;
    }
  }

  // stylelint-disable-next-line selector-class-pattern
  &.annotationsActive :global(.react-pdf__Page__annotations) {
    z-index: $canvas-z-index;
  }
}

.markedText {
  background-color: $color-search-hit;
  color: transparent;
  padding: 0;
}

.bulkAddCheckbox {
  position: absolute;
  top: $spacing-xs;
  right: $spacing-xs;
}

.overlay {
  position: absolute;
  z-index: 2500;
  inset: 0;
}

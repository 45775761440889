// Embla does not update status icon position on window resize - pin it to the right
.form {
  :global(.element-status-icon) {
    left: auto !important;
    right: 0 !important;
  }

  :global(.row > div > .form-group > .element-status-icon) {
    right: 15px !important;
  }
}

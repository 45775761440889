@import "src/content/styles/variables";

.cardsContainer {
  flex-grow: 1;
  flex-shrink: 0;
  overflow: hidden auto;
  padding-left: 8px; // To show shadow
  padding-right: 8px;

  &.presentationEdit {
    background-color: $color-gray-95;
  }
}

.overlayElement {
  position: absolute;
  z-index: 1001;
}

.highlight {
  z-index: 1002;
}

.overlayBtn {
  z-index: 1003;
}

.notActive {
  &,
  * {
    pointer-events: none !important;
  }
}

.canvas {
  z-index: 1000;
  position: absolute;

  &.noMarkingsAllowed:hover {
    cursor: not-allowed;
  }
}

.transparentCardHeader {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  margin-bottom: 16px;
  margin-top: 1px;
}

.markingCardContainer {
  display: flex;
  align-items: center;
  margin-bottom: $margin-m;

  > :global(.marking-card) {
    flex: 1;
    margin-bottom: 0;
    max-width: 100%;
  }

  > :global(.marking-presentation-switch) {
    flex-grow: 0;
  }
}

:global(.card) {
  &.addedToPresentation {
    border-color: $color-primary;
  }
}

.transitions {
  transition:
    box-shadow $default-transition,
    border $default-transition,
    opacity $default-transition,
    background-color $default-transition;
}

.faded {
  opacity: $faded-marking-opacity;
}

.commentFaded {
  opacity: $faded-comment-opacity;
}

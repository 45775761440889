@import "src/content/styles/embla-variables";

.container {
  position: absolute;
  padding: 8px;
  min-width: 40px;
  text-align: center;
  z-index: 1000 !important;
  transform: translate(-50%, -50%);
}

.border {
  border: 1px dashed #cbafaf;
}

.disabled {
  pointer-events: none;
}

.whiteBackground {
  background-color: $color-white !important;
}

/* stylelint-disable selector-class-pattern */
@import "src/content/styles/embla-variables";

.emptyPage {
  margin: var(--margins);

  &.sizeNormal {
    height: 120px;
    width: 87px;
  }

  &.active {
    outline: $color-primary solid 3px;
  }

  &.sizeSmall {
    height: 60px;
    width: 43px;
  }

  &.addNewPage {
    border: 2px dashed #dbdbdb !important;
  }

  &.noThumbnailPlaceholder {
    background-color: $color-gray-95;
    box-shadow: $shadow-1;
  }
}

@import "src/content/styles/embla-variables";

/* Fix embla checkbox style with empty label */
label.label:empty {
  height: 1rem;
  padding-left: 18px !important;
}

input.input:not(:checked) + label.label::before {
  background-color: $color-white;
}

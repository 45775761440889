.smallRows {
  tbody {
    td,
    th {
      padding-top: 3px !important;
      padding-bottom: 3px !important;
      align-content: center;
    }
  }

  thead {
    tr:first-child {
      th {
        padding-top: 8px !important;
        padding-bottom: 3px !important;
      }
    }

    th {
      padding-top: 4px;
      padding-bottom: 4px;
      align-content: center;
    }
  }

  th::after {
    top: 2px !important;
  }
}

.checkbox {
  height: 35px;
}

.firstColumnNoPadding {
  tbody {
    td:first-child {
      padding: 0 !important;
    }
  }
}

@import "src/content/styles/variables";

/* css hack for highlighting textlayer (textlayer is not 100% aligned with background text */
/* stylelint-disable-next-line */
:global(.react-pdf__Page__textContent) span {
  opacity: 0.2;

  &::selection {
    background-color: blue; // stylelint-disable-line
  }
}

.documentViewer {
  display: flex;
  flex-direction: column;
  height: calc(100vh - $topbar-heigth);
}

.documentViewport {
  flex: 1;
  overflow: hidden;
}

.documentWrapper {
  margin-left: $pdf-sidebar-width + 5px;
}

.dragPreviewPagePlaceholder {
  background-color: #fff !important;
  opacity: 0.5;
}

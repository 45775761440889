.container {
  position: fixed;
  bottom: 0;
  left: 80%;
  //max-width: max-content;
  transform: translateX(-20%);
  z-index: 9999;
  background: #333;
}

.newPageBtn {
  background-color: #0000 !important;
  color: #fff !important;
  border-color: #fff !important;

  svg {
    fill: #fff !important;
  }
}

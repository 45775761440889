@import "src/content/styles/embla-variables";

.wizardPanel {
  border: 0;
  height: 100%;

  .wizardStepContent {
    font-size: $font-size-root !important;
    line-height: $line-height-large;
  }

  .wizardContent {
    min-height: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap !important;
    overflow: hidden;

    .headerAndContent {
      flex: 1 1 auto;
      overflow-y: auto;
      overflow-x: hidden;

      // Fix for box-shadows getting cutoff, when overflow-x is hidden
      padding: 10px;
      margin: -10px;
    }

    .actions {
      margin-top: 24px;
    }
  }
}

@import "src/content/styles/variables";

.scrollable {
  overflow-y: auto;
}

.bottomBarOpenHeight {
  height: calc(100vh - $toolbar-height - $topbar-heigth - $bottombar-height - $tab-button-height);
}

.bottomBarClosedHeight {
  height: calc(100vh - $toolbar-height - $topbar-heigth - $tab-button-height);
}

@import "src/content/styles/embla-variables";

.container {
  padding: 12px 8px;
  border-radius: 4px;
}

.noDocument {
  border: 1px dashed $color-gray-70;
  background-color: #f6f6f6;
  box-shadow: none;
  cursor: default;
}

.withDocument {
  border: 1px solid $color-gray-85;
  background-color: $color-white;
  cursor: pointer;

  &:hover {
    background-color: $color-gray-95;
  }
}

.deleteIconContainer {
  width: 64px;
  display: flex;
  justify-content: flex-end;
}

.hoverWithDocument {
  margin: 0;
  border: 2px solid $color-primary;
}

.dropNotAllowed {
  cursor: not-allowed !important;
}

/* stylelint-disable selector-class-pattern */
@import "src/content/styles/embla-variables";

.bookmarkChildren {
  margin-left: 11px;
  padding-left: 5px;
  border-left: 2px solid $color-gray-95;
}

.bookmarkTreeIndicator {
  position: absolute;
  left: -14px;
  top: -5px;
}

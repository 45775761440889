@import "src/content/styles/embla-variables";

.groupsBox {
  border: 2px solid $color-gray-85;
  padding: $spacing-s;
  padding-bottom: 0;
  margin-bottom: $spacing-m;
}

.radioGroup {
  display: flex;
  gap: 11px;

  input {
    &:checked + .radio {
      outline: $color-primary solid;
      outline-offset: 1px;
    }

    &:focus + .radio {
      border-color: $form-control-focus-border-color;
      box-shadow: 0 0 0 6px rgba($form-control-focus-border-color, $outline-border-opacity);
    }
  }
}

.selected {
  border-color: $form-control-focus-border-color;
  box-shadow: 0 0 0 6px rgba($form-control-focus-border-color, $outline-border-opacity);
}

.radio {
  cursor: pointer;
  width: 32px;
  height: 32px;
}

.borderDark {
  border: 2px solid $color-gray-60;
}

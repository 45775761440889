@import "src/content/styles/embla-variables";
@import "src/content/styles/variables";

.toolbar {
  min-height: $toolbar-height;
  z-index: 999;
  display: grid;
  grid-template-columns: min-content minmax(0, 1fr) min-content;
  gap: 15px;
}

.subtitle {
  height: #{$line-height-medium}em;
}

.item :global(.form-control) {
  color: var(--toolbar-control-text-color);
}

@media (max-width: 1000px) {
  .toolbar.toolbar {
    padding-top: $spacing-s !important;
    padding-bottom: $spacing-s !important;
  }

  .hiddenWhenWraps {
    display: none;
  }

  .pdfInfo {
    flex-basis: 100% !important;
  }
}

@import "src/content/styles/embla-variables";

.presentationCard {
  box-shadow: none;

  margin-bottom: 0.5rem;
  padding: 0;
  max-width: 600px;

  .cardBody {
    align-items: center;
    display: flex;
    padding: 0 !important;

    .presentationContent {
      display: flex;
      flex: 1;

      :global(.form-group) {
        margin-bottom: 0;
      }

      .presentationNumber {
        align-items: center;
        border-right: 1px solid $gray-light;
        display: flex;
        padding-left: 15px;
        padding-right: 20px;
      }

      .input {
        padding: 10px;
        width: 100%;
      }
    }

    .deletePresentationBtn {
      margin-right: 8px;

      svg {
        fill: $color-primary;
      }
    }
  }
}

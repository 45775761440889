@import "src/content/styles/embla-variables";

.bottomBar {
  --bottombar-height: 170px;
  --bottombar-collapsed: 42px;
  background-color: $color-tertiary;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.1);

  .thumbnailContainer {
    overflow-x: auto;
  }
}

.opaque {
  opacity: 0.5;
}

.modal {
  z-index: 20000 !important;
}

.overflowButton {
  transform: rotate(90deg);
  margin-right: 15px;
}

.divider {
  margin-top: 2px;
  margin-bottom: 2px;
  border: 1px solid $color-gray-85;
}

@import "src/content/styles/embla-variables";

.resizeHandle {
  height: 100%;
  cursor: ew-resize;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;

  &:hover {
    opacity: 1;
  }

  hr {
    flex: 1;
    width: 1px;
    background-color: $color-gray-70;
    margin: 0;
  }
}

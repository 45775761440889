@charset "UTF-8";

@import "content/styles/variables";

@import "content/styles/shared";
@import "content/styles/colors";
@import "content/styles/dropdownMenu";

$embla-icons-font-path: "~ditmer-embla-icons/dist/icons/font";
@import "ditmer-embla-icons/dist/icons/font/embla-icons";
@import "ditmer-embla-icons/lib/scss/embla-icons";

@import "ditmer-embla/lib/scss/embla";
@import "content/styles/embla-overwrites";

.page-content .title-bar {
  background-color: $color-white;
}

.login-header .login-language-picker {
  margin-left: auto;
  margin-right: $margin-l;
}

.only-one-language {
  padding: $padding-xs $padding-s;
}

button[disabled] {
  cursor: not-allowed;
}

.min-w-190 {
  min-width: 190px;
}

.min-w-0 {
  min-width: 0;
}

.relative {
  position: relative;
}

.h-full {
  height: 100%;
}

.margin-left-auto {
  margin-left: auto;
}

.gap-1 {
  gap: 1px;
}

.gap-2 {
  gap: 2px;
}

.gap-8 {
  gap: 8px;
}

.scrollbar {
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-gray-70;
    border: 5px solid #fff;
    border-radius: 16px;
    min-height: 15%;
  }
}

.background-gray {
  background-color: $color-gray-95;
}

strong,
b {
  font-weight: $font-weight-bold;
}

.flex-1 {
  flex: 1;
}

.ellipsisContainer {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.small-gutters {
  margin-left: -$spacing-s;
  margin-right: -$spacing-s;

  .col,
  [class*="col-"] {
    padding-right: $spacing-s;
    padding-left: $spacing-s;
  }
}

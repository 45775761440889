.cropContainer {
  position: relative;
  width: 100%;
  height: 500px;
}

.currentProfilePicture {
  height: 200px;
}

.profilePictureContainer {
  position: relative;
  min-height: 200px;
}

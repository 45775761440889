@import "src/content/styles/embla-variables";

.roundBadge {
  align-items: center;
  border-radius: 50%;
  color: $color-white;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 80px;
  flex: 0 0 auto;

  &--m {
    height: 40px;
    width: 40px;
    font-size: 12px;
  }

  &--s {
    height: 32px;
    width: 32px;
    font-size: 10px;
  }

  &--xs {
    height: 24px;
    width: 24px;
    font-size: 8px;
  }

  &--xxs {
    height: 18px;
    width: 18px;
    font-size: 6px;
  }

  &--primary {
    background-color: $color-primary;
  }

  &--secondary {
    color: $color-gray-15;
    background-color: $color-secondary;
  }

  &--secondary-light {
    color: $color-gray-15;
    background-color: $color-secondary-light;
  }
}

@import "src/content/styles/embla-variables";

.card {
  border-end-end-radius: 0 !important;
  border-end-start-radius: 0 !important;
  box-shadow: unset !important;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-bottom: unset !important;
  background-color: $color-white;
}

@import "src/content/styles/embla-variables";
@import "src/content/styles/variables";

.badge {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  margin-right: $margin-m;
  border: 4px solid #fff;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.localDevelopmentBadge {
  background-color: $color-danger-dark;
}

.developmentBadge {
  background-color: $color-danger;
}

.demoBadge {
  background-color: $color-info-dark;
}

.testBadge {
  background-color: $color-success-dark;
}

.handle {
  --handle-offset: -12px;
  --handle-size: 20px;
  position: absolute;
}

.east,
.west {
  height: 100%;
  width: var(--handle-size);
}

.east {
  right: var(--handle-offset);
}

.west {
  left: var(--handle-offset);
}

.north,
.south {
  height: var(--handle-size);
  width: calc(100% + (var(--handle-offset) * -2));
  left: var(--handle-offset);
}

.north {
  top: var(--handle-offset);
}

.south {
  bottom: var(--handle-offset);
}

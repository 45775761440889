@import "src/content/styles/embla-variables";

.rotate {
  transform: rotate(180deg);
}

.navigationButton {
  width: 28px !important;
  height: 28px !important;
}

.pageContainer:hover {
  cursor: pointer;
}

button.topButton:not(:active) {
  background-color: $color-white;
}

@import "src/content/styles/embla-variables";

.progressLine {
  border-radius: 10px;
  background-color: #f1f1f1;
  margin-bottom: 0.2rem;

  &:global(.progress) {
    height: 0.5rem;
  }

  .progressBar {
    border-radius: 0;
    width: var(--progressPercentage);
    transition: width 0.5s ease-out;
  }

  .progress-bar:first-child {
    border-radius: 10px 0 0 10px;
  }

  .progress-bar:last-child {
    border-radius: 0 10px 10px 0;
  }
}

.Info {
  background-color: $color-info;
}

.Warning {
  background-color: $color-warning;
}

.Danger {
  background-color: $color-danger;
}

.Primary {
  background-color: $color-primary;
}

.PrimaryLight {
  background-color: $color-primary-light;
}

.Secondary {
  background-color: $color-secondary;
}

.Success {
  background-color: $color-success;
}

.Gray {
  background-color: $color-gray-95;
}

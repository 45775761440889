@import "src/content/styles/embla-variables";

.colorBlue {
  fill: $color-primary !important;
}

.colorWhite {
  fill: $color-white !important;
}

.colorGray {
  fill: $color-gray-85 !important;
}

.colorWarning {
  fill: $color-warning !important;
}

.colorDanger {
  fill: $color-danger !important;
}

.colorDarkGray {
  fill: $color-gray-15 !important;
}

.colorGray70 {
  fill: $color-gray-70 !important;
}

.iconSmall {
  width: 14px !important;
  height: 14px !important;
}

.iconExtraSmall {
  width: 12px !important;
  height: 12px !important;
}

.iconMedium {
  width: 18px !important;
  height: 18px !important;
}

.iconLarge {
  width: 24px !important;
  height: 24px !important;
}

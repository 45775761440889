@import "src/content/styles/embla-variables";

.dropdown {
  position: relative;

  .input {
    width: 100px;
    border-radius: 0;
  }

  .small {
    width: 50px;
  }

  .dropdown {
    min-width: max-content;
    width: 100%;
    right: 0;
    left: auto;
  }

  .dropdownBtn {
    background-color: transparent;
    border: 0;
    padding: 0;
    position: absolute;
    right: 4px;
    top: 4px;
    bottom: 4px;
  }
}

.transparent {
  background-color: transparent !important;
  border: unset !important;
}

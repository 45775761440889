.header {
  outline: none;
  position: relative;
}

.clickableHeader {
  cursor: pointer;
}

.sortIcon::after {
  content: "\f163";
  font-family: "embla-icons";
  font-size: 18px;
  margin-left: 8px;
  opacity: 0.7;
  position: absolute;
  top: 12px;
}

.sortAsc::after {
  content: "\f161";
  opacity: 1;
}

.sortDesc::after {
  content: "\f162";
  opacity: 1;
}

@import "src/content/styles/embla-variables";

.nav {
  &.nav-tabs {
    background-color: $color-white;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    margin-bottom: 0;

    &:not(.hide-border) {
      box-shadow: $shadow-1;
    }

    &.hide-top-border {
      border-top: unset !important;
    }

    &.tab-group.hide-border {
      border: 0;
    }
  }
}

.tab-content > .tab-pane > .card {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.tab-notactive {
  flex: 1 1;
  align-items: center;
  border: 0;
  background-color: $color-white;
  color: $color-gray-60;
  display: flex;
  flex-direction: column;
  font-weight: $font-weight-bold;
  height: 100%;
  letter-spacing: 1px;
}

.tab-notactive:hover {
  border-bottom: 3px solid $color-primary;
}

.tab-active {
  align-items: center;
  color: $text-heading-color;
  border-bottom: 3px solid $color-primary;
}

.tab-active:focus {
  outline: none;
  z-index: 10;

  &:not(.hide-border):not(.hide-top-border) {
    box-shadow: 0 0 0 3px $color-primary-light;
  }
}

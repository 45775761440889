@import "src/content/styles/embla-variables";

.container {
  cursor: pointer;
  padding-top: 7px;
  padding-bottom: 7px;

  &:hover {
    background-color: $color-gray-95;
  }
}

.textStart {
  text-align: start;
}

.editIconContainer {
  width: 24px;
}

.opaque {
  opacity: 0.5 !important;
}

.caseDocumentName {
  max-width: 500px;
}

@import "src/content/styles/embla-variables";

.container {
  position: absolute;
  left: $spacing-s;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3000;
  display: flex;
  gap: $spacing-s;
  align-items: center;
}

.colorBtn {
  width: 24px;
  height: 24px;

  &.active {
    border: 4px rgb(255, 255, 255) solid;
    outline: $color-primary solid;
    outline-offset: -3px;
  }
}

@import "src/content/styles/variables";

$subject-height: 19px;
$subjects-gap: 2px;
$name-length: 140px;

.markingIcon {
  svg {
    fill: $color-icon;
    width: 24px;
    height: 24px;
  }
}

.presentationsContainer {
  max-height: $subject-height;
  max-width: 100%;
}

.presentationBadge {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.isPrivate {
  align-items: center;
  display: flex;
}

.tagNameOverflow {
  max-width: $name-length;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nameContainer {
  max-width: $name-length;
}

@import "src/content/styles/embla-variables";

.container {
  cursor: pointer;

  &:hover {
    background-color: $color-gray-95;
  }
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected {
  background-color: #dbf0fa;
}

.dragging {
  background-color: #fff !important;
  box-shadow: $shadow-1;
}

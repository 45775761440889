div.container {
  height: 100vh;
  background: #2f3345;

  &,
  * {
    cursor:
      url("/content/images/laser-pointer.svg") 7 7,
      crosshair !important;
  }
}

.header {
  font-size: 20px;
}

@import "src/content/styles/variables";

.input:global(.ant-picker-focused) {
  box-shadow: $outline-box-shadow rgba($form-control-focus-border-color, $outline-border-opacity);
}

.clear {
  margin-right: -5px;
}

.popup {
  z-index: 99999;
}

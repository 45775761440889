@import "src/content/styles/embla-variables";

.preview {
  min-width: 600px;
}

.pointerActive {
  cursor:
    url("/content/images/laser-pointer.svg") 7 7,
    crosshair !important;
}

.toolbox {
  cursor: initial;
}

.sidebar {
  background-color: #f2f0f0;
}

@import "src/content/styles/embla-variables";

.uploadFilesCard {
  align-items: center;
  border: $default-border;
  border-radius: $border-radius;
  border-style: dashed;
  display: flex;
  justify-content: space-between;
  padding: 50px 35px;

  div:first-child {
    display: flex;
  }
}

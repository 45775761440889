@import "src/content/styles/embla-variables";

.mentionCard {
  min-width: 300px !important;
  background-color: $color-tertiary !important;
  margin: 0 !important;
  padding: 0 !important;
}

.mentionCardBody {
  background-color: #fff;
  padding: 0 !important;
}

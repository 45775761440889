@import "src/content/styles/embla-variables";

.container {
  position: absolute !important;
  box-shadow: unset !important;
  width: max-content !important;
}

.templateStyles {
  top: 7px;
  left: 20%;
}

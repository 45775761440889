.cursorPointer {
  cursor: pointer;
}

.sectionTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

@import "src/content/styles/embla-variables";

.documentsContainer {
  margin-top: $margin-m;
}

.documentsTableHeader {
  padding-top: 0 !important;
  border-top: 0 !important;
}

.documentSwitchWithLabelContainer {
  display: inline-flex;
  align-items: center;
  text-align: start;
}

.verticallinesSwitchHeaderContainer {
  display: inline-flex;
  align-items: center;
  text-align: end;
  padding-right: $padding-s;
}

.verticallinesSwitchLabel {
  white-space: nowrap;
  margin-right: $margin-s;
}
